import React from 'react';
import { useAuth } from '../contexts/AuthContext'; // Adjust the path to your AuthContext

const HowToPlay = ({ cases }) => {
    const { user } = useAuth(); // Access user object from context

    const handleCaseClick = async (caseId) => {
        if (!user || !user.id) {
            alert("User not authenticated");
            return;
        }

        try {
            const response = await fetch(`/api/trigger-case/${caseId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: user.id, // Pass the user ID to the backend
                }),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            console.log("Case triggered successfully:", data);

            // Handle success, e.g., show alert or update UI
            alert(`You received: ${data.selected_item.name}`);
        } catch (error) {
            console.error("Failed to trigger case:", error);
            alert("An error occurred while triggering the case.");
        }
    };

    return (
        <section className="how-to-play-section">
            <div className="steps">
                {cases.map((caseItem) => (
                    <div
                        className="step"
                        key={caseItem.id}
                        onClick={() => handleCaseClick(caseItem.id)} // Add click handler
                        style={{ cursor: 'pointer' }} // Change cursor to pointer for better UX
                    >
                        <img src={caseItem.image_url} alt={caseItem.name} />
                        <div className="info-box">
                            <h3>{caseItem.name}</h3>
                            <p className="common-button">{caseItem.price}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default HowToPlay;
