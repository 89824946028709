import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const UserProfile = () => {
  const { user, isLoggedIn, refreshUserData, giftedCaseNotificationCount, setGiftedCaseNotificationCount } = useAuth();
  const [casesData, setCasesData] = useState([]);
  const [giftedCasesData, setGiftedCasesData] = useState([]); // State for gifted cases
  const [usedGiftedCases, setUsedGiftedCases] = useState([]); // State for used gifted cases
  const [userData, setUserData] = useState({
    steamId: '',
    username: '',
    avatar: '',
    balance: 0,
    experience: 0,
    level: 1,
  });
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1); // Track which tab is active (1: clicked cases, 2: gifted cases)
  const navigate = useNavigate();

  // Function to calculate XP required for the next level
  const getXpForLevel = (level) => {
    if (level === 1) return 100;
    if (level >= 2 && level <= 31) return 100 + (level - 1) * 300;
    if (level >= 32 && level <= 41) return getXpForLevel(31) + (level - 31) * 400;
    if (level >= 42 && level <= 50) return getXpForLevel(41) + (level - 41) * 500;
    return 0;
  };

  // Function to fetch user data
  const fetchUserData = async () => {
    if (!user?.id) return;
    try {
      const response = await fetch(`/user/${user.id}`);
      const data = await response.json();

      if (data.error) {
        console.error('Error fetching user data:', data.error);
      } else {
        setUserData({
          steamId: data.id,
          username: data.username,
          avatar: data.avatar,
          balance: data.balance,
          experience: data.experience,
          level: data.level,
        });
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

const fetchClickedCases = async () => {
    if (user && user.cases && user.cases.length > 0) {
        setLoading(true);
        console.log("🚀 ~ fetchClickedCases ~ user.cases:", user.cases);

        try {
            const response = await fetch(`/api/get-cases-by-ids?case_ids=${user.cases.join(',')}`);
            console.log("🚀 ~ API response:", response);

            const data = await response.json();
            console.log("🚀 ~ Parsed data:", data);

            if (data && Array.isArray(data) && data.length > 0) {
                setCasesData(data);
                console.log("Cases data successfully updated:", data);
            } else {
                console.warn("No cases data found in response.");
            }
        } catch (error) {
            console.error("Error fetching clicked cases:", error);
        } finally {
            setLoading(false);
        }
    } else {
        console.warn("User or user cases data is missing.");
    }
};


  // Fetch gifted cases (from the `gifted_cases` array)
  const fetchGiftedCases = async () => {
    if (user && user.id) {
      setLoading(true);
      try {
        const response = await fetch(`/api/get-gifted-cases?user_id=${user.id}`);
        const data = await response.json();

        console.log("🚀 ~ fetchGiftedCases ~ data:", data); // Debugging response

        if (data.error) {
          console.error(data.error);
        } else {
          setGiftedCasesData(data.gifted_cases || []); // Set gifted cases
          setUsedGiftedCases(data.used_gifted_cases || []); // Set used gifted cases
          console.log("🚀 ~ fetchGiftedCases ~ data.gifted_cases:", data.gifted_cases);
          console.log("🚀 ~ fetchGiftedCases ~ data.used_gifted_cases:", data.used_gifted_cases);
        }
      } catch (error) {
        console.error('Error fetching gifted cases:', error);
      } finally {
        setLoading(false);
      }
    }
  };
    // Fetch user data and refresh it
    const updateUserData = async () => {
        if (user?.id) {
          await refreshUserData(user.id); // Refresh user data via AuthContext
        }
      };
    

  // Handle clicking on a gifted case
  const handleGiftedCaseClick = async (caseId) => {
    try {
        const response = await fetch(`/api/use-gifted-case`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: user.id, case_id: caseId }),
        });

        if (response.ok) {
            setUsedGiftedCases((prev) => [...prev, caseId]);

            // Update notification count
            const newCount = giftedCaseNotificationCount - 1;
            setGiftedCaseNotificationCount(newCount >= 0 ? newCount : 0); // Ensure count doesn't go negative
        } else {
            console.error('Failed to mark case as used.');
        }
    } catch (error) {
        console.error('Error handling gifted case click:', error);
    }
};

  // Fetch user data when the component mounts

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    } else {
      updateUserData(); // Always refresh user data on mount
      fetchUserData();
      fetchClickedCases();
      
    }
  }, [isLoggedIn, navigate]);

  // Fetch data based on the active tab
  useEffect(() => {
    if (activeTab === 1) {
      fetchClickedCases();
    } else if (activeTab === 2) {
      fetchGiftedCases();
    }
  }, [activeTab, user]);

  // Calculate progress bar width and text
  const xpForNextLevel = getXpForLevel(user.level);
  const progressPercentage = Math.min((user.experience / xpForNextLevel) * 100, 100);

  if (loading || !isLoggedIn) {
    return <div>Loading...</div>;
  }

  return (
    <div className="user-profile-section">
      <div className="user-profile-card">
        <div className="user-card">
          <div className="user-avatar">
            <img src={user.avatar} alt={user.username} className="avatar-img" />
          </div>
          <div className="user-details">
            <div className="user-name">{user.name}</div>
            {/* <button className="level-up-btn" onClick={updateUserData}>
              Обновить данные
            </button> */}
              <div className="level-info">
                <span>Уровень: {user.level}</span>
              </div>
            <div className="progress-container">
              <div className="progress-bar" style={{ width: `${progressPercentage}%` }}>
                {progressPercentage.toFixed(0)}%
              </div>
            </div>
            <div className="experience-info">
              <span>
                {user.experience}/{xpForNextLevel} XP
              </span>
              <span className="how-it-works">Как это работает?</span>
            </div>
          </div>
        </div>
        <div className="user-balances">
          <div className="balance">{user.balance} ₽</div>
          <div className="balance">0 Монеты</div>
          <button className="fill-ballance">Пополнить баланс</button>
        </div>
        <div className="account-info">
          <div className="account-status">Аккаунт не подтвержден</div>
          <button className="confirm-account-btn">Подтвердить аккаунт</button>
          <button className="partner-program-btn">Партнерская программа...</button>
        </div>
      </div>

      <div className="tab-buttons">
        <button
          className={`tab-btn ${activeTab === 1 ? 'active' : ''}`}
          onClick={() => setActiveTab(1)}
        >
          Купленные кейсы
        </button>
        <button
          className={`tab-btn ${activeTab === 2 ? 'active' : ''}`}
          onClick={() => setActiveTab(2)}
        >
          Подарки
        </button>
      </div>

      <div className="items-display">
        {activeTab === 1 && casesData.length > 0 ? (
          casesData.map((caseItem, index) => (
            <div className="item-container" key={index}>
              <div className="image-container">
                <img src={caseItem.image_url} alt={caseItem.name} />
              </div>
              <div className="info">
                <h3>{caseItem.name}</h3>
                <p>Price: {caseItem.price} ₽</p>
              </div>
            </div>
          ))
        ) : activeTab === 2 && giftedCasesData.length > 0 ? (
          giftedCasesData.map((giftedCase, index) => (
            <div
              className={`item-container ${
                usedGiftedCases.includes(giftedCase.id) ? 'disabled' : ''
              }`}
              key={index}
              onClick={() =>
                !usedGiftedCases.includes(giftedCase.id) && handleGiftedCaseClick(giftedCase.id)
              }
              style={{
                cursor: usedGiftedCases.includes(giftedCase.id) ? 'not-allowed' : 'pointer',
                opacity: usedGiftedCases.includes(giftedCase.id) ? 0.5 : 1,
              }}
            >
              <div className="image-container">
                <img src={giftedCase.image_url} alt={giftedCase.name} />
              </div>
              <div className="info">
                <h3>{giftedCase.name}</h3>
                <p>Price: {giftedCase.price} ₽</p>
              </div>
            </div>
          ))
        ) : (
          <p>No data found. Please check your list.</p>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
