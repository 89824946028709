import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-stats">
                <div className="stat">
                    <span className="stat-number">500,958,175</span>
                    <span className="stat-label">открыто кейсов</span>
                </div>
                <div className="stat">
                    <span className="stat-number">11,634,266</span>
                    <span className="stat-label">игроков</span>
                </div>
                <div className="stat">
                    <span className="stat-number">715,099</span>
                    <span className="stat-label">батлов</span>
                </div>
                <div className="stat">
                    <span className="stat-number">12,515,165</span>
                    <span className="stat-label">контрактов</span>
                </div>
                <div className="stat">
                    <span className="stat-number">8,820,900</span>
                    <span className="stat-label">апгрейдов</span>
                </div>
            </div>
            <div className='footer-wrapper'>
                <div className="footer-content">
                    <div className="footer-logo-section">
                        <div className="footer-logo">
                            <img className="main-logo" src="images/dota2.webp" alt="Logo" />
                        </div>
                        <div className="footer-copyright">
                            &copy; OPENCASE123.ru © 2016-2025 All rights reserved<br />
                            Molteon Pte. Limited, 13 Keppel Bay Drive, #01-25, Corals at Keppel Bay, Singapore (098018)<br />
                            EU operations: Yomoly LTD, Charalampou Mouskou, 20, ABC CENTER, 4th floor, office 406, 8010, Paphos, Cyprus
                        </div>
                    </div>
                    <div className="footer-social">
                        <div className="social-icons">
                            <span>Мы в соц. сетях</span>
                            <a href="https://vk.com" target="_blank" rel="noopener noreferrer">
                                <img src="images/VK Logo White.svg" alt="VK" />
                            </a>
                            <a href="https://telegram.org" target="_blank" rel="noopener noreferrer">
                                <img src="images/tg.svg" alt="Telegram" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-links-section">
                    <div className="footer-left">
                        <div className="footer-links">
                            <a href="#">Terms of Use</a>
                            <a href="#">Privacy Policy</a>
                        </div>
                        <div className="footer-info">
                            <span>
                                Powered by Steam. Not affiliated with Valve Corp. You can buy various skins on our website CS 2 at best prices.
                            </span>
                        </div>
                        <div className="payment-methods">
                            <img src="images/visa.png" alt="Visa" />
                            <img src="images/mastercard.png" alt="MasterCard" />
                            <img src="images/btc.png" alt="btc" />
                            <img src="images/busd.png" alt="busd" />
                            <img src="images/interac.png" alt="interac" />
                            <img src="images/skrill.png" alt="skrill" />
                        </div>
                    </div>
                    <div className="footer-right">
                        <div className="ssl-steam-container">
                            <div className="ssl-info">
                                <img src="images/shield.svg" alt="SSL Icon" />
                                <span>SSL 256-Bit <br />RSA Encryption</span>
                            </div>
                            <div className="steam-button">
                                <img src="images/steam-button.png" alt="Steam Logo" />
                            </div>
                        </div>
                        {/* <div className="support-button">
                            <button>Support</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
