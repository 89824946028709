import React from 'react';
import Header from './components/Header';
import WinnersSlider from './components/WinnersSlider';
import HeroSection from './components/HeroSection';
import LineContainer from './components/LineContainer';
import HowToPlay from './components/HowToPlay';
import Footer from './components/Footer';
import MainImage from './components/MainImage';
import Overlay from './components/Overlay';

const Home = ({ slideImages, casesData }) => {
    // Filter cases by price
    const paidCases = casesData.filter((caseItem) => caseItem.price > 0); // Cases with price > 0
    const freeCases = casesData.filter((caseItem) => caseItem.price === 0); // Cases with price = 0

    return (
        <div>
            <MainImage />
            <Overlay />
            <Header />
            <WinnersSlider slides={slideImages} />
            <HeroSection />
            <LineContainer title="TOP CASES" />
            <HowToPlay cases={paidCases} />
            <LineContainer title="FREE CASES" />
            <HowToPlay cases={freeCases} />
            <Footer />
        </div>
    );
};

export default Home;
