import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        // Load user from localStorage on component mount
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });
    const [isLoggedIn, setIsLoggedIn] = useState(!!user);   
    const [giftedCaseNotificationCount, setGiftedCaseNotificationCount] = useState(0); // Notification count


    useEffect(() => {
        const savedUser = localStorage.getItem('user');
        if (!savedUser) {
            setUser(null);
            setIsLoggedIn(false);
        }
    }, []);

    // Listen for changes to localStorage to log out the user if data is removed
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'user' && !event.newValue) {
              // User data was removed from localStorage
              setUser(null);
              setIsLoggedIn(false);
              localStorage.removeItem('user');
              window.location.href = '/';
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

      // Function to refresh user data from backend
    const refreshUserData = async (userId) => {
        try {
        const response = await axios.get(`/user/${userId}`); // Replace with your API endpoint
        if (response.data) {
            setUser(response.data); // Update the full user object
            setIsLoggedIn(true); // Ensure the login state is updated
            console.log('User data refreshed:', response.data);
        }
        } catch (error) {
        console.error('Error refreshing user data:', error);
        }
    };

    const login = (userData) => {
        setUser(userData);
        setIsLoggedIn(true);
        console.log("🚀 ~ login ~ userData:", userData)
        localStorage.setItem('user', JSON.stringify(userData)); // Persist user data
    };

    const logout = () => {
        setUser(null);
        setIsLoggedIn(false);
        setGiftedCaseNotificationCount(0); 
        localStorage.removeItem('user'); // Remove user from localStorage
    };

    return (
        <AuthContext.Provider value={{ user, isLoggedIn, login, logout, setUser, refreshUserData, giftedCaseNotificationCount, setGiftedCaseNotificationCount}}>
            {children}
        </AuthContext.Provider>
    );
};
