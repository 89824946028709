import LoginModal from './LoginModal'; // Import the LoginModal component
import axios from 'axios'; // You'll need axios to make HTTP requests
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Correct import of useAuth

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the URL search params
  const { isLoggedIn, login, logout, user, giftedCaseNotificationCount, setGiftedCaseNotificationCount } = useAuth(); // Get authentication state and functions
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [userData, setUserData] = useState(null); // State to store fetched user data
  const [loading, setLoading] = useState(false); // Loading state for async operations
  const [giftedCases, setGiftedCases] = useState([]); // Track gifted cases
  const [unopenedCasesCount, setUnopenedCasesCount] = useState(0); // Track unopened cases count

  const handleLoginFromURL = () => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');

    if (id && !user) {
      // Fetch user data only if user is null
      fetchUserData(id);
    }
  };

  const fetchUserData = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`/user/${id}`); // Make an API request to fetch user data from DB
      if (response.data) {
        setUserData(response.data); // Update the user data state with the fetched data
        console.log('🚀 ~ fetchUserData ~ response.data:', response.data);

        // Once user data is fetched, login with the data
        login(response.data);

        // Fetch gifted cases for the user
        fetchGiftedCases(response.data.id);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

const fetchGiftedCases = async (userId) => {
  try {
    const response = await axios.get(`/api/get-gifted-cases?user_id=${userId}`);
    if (response.data) {
      const giftedCases = response.data.gifted_cases || [];
      const usedGiftedCases = response.data.used_gifted_cases || [];

      console.log("Gifted Cases:", giftedCases);
      console.log("Used Gifted Cases:", usedGiftedCases);

      // Correct logic to calculate unopened cases
      const unopenedCasesCount = giftedCases.filter(
        (giftedCase) =>
          usedGiftedCases.filter((usedId) => usedId === giftedCase.id).length <
          giftedCases.filter((giftedId) => giftedId.id === giftedCase.id).length
      ).length;

      setGiftedCases(giftedCases);
      setUnopenedCasesCount(unopenedCasesCount);
      setGiftedCaseNotificationCount(unopenedCasesCount)
      console.log("Unopened Cases Count:", unopenedCasesCount);
    }
  } catch (error) {
    console.error('Error fetching gifted cases:', error);
  }
};

  

  useEffect(() => {
    handleLoginFromURL(); // Check for user data in URL when it changes
  }, [location.search]); // When the search params change (i.e., after redirect)

  const handleHomeClick = () => {
    navigate('/'); // Redirect to the home route
  };

  const handleCasesClick = () => {
    navigate('/cases'); // Redirect to the cases route
  };

  const handlePaymentClick = () => {
    navigate('/payment'); // Redirect to the payment route
  };

  const handleProfileClick = () => {
    navigate('/profile'); // Redirect to the profile route
  };

  const handleLoginClick = () => {
    setShowModal(true); // Show the login modal
  };

  const closeModal = () => {
    setShowModal(false); // Close the modal
  };

  const handleLogout = () => {
    logout(); // Set login state to false
    setUserData(null); // Clear user data when logging out
    setGiftedCases([]); // Clear gifted cases on logout
    navigate('/'); // Redirect to home after logging out
  };

  useEffect(() => {
    if (user) {
      setUserData(user);
      fetchGiftedCases(user.id); // Fetch gifted cases on user login
    }
  }, [user]);

  return (
    <header className="content-header">
      <div className="logo">
        <img className="main-logo" src="/images/dota2.webp" alt="Game Logo" />
      </div>
      <div className="menu-container">
        <a href="#!" onClick={handleHomeClick}>
          <div className="common-button">
            <img src="/images/menu/home.webp" alt="Home" />
          </div>
        </a>
        <a href="#!" onClick={handleCasesClick}>
          <div className="common-button">
            <img src="/images/menu/cases.webp" alt="Cases" />
          </div>
        </a>
        <div className="common-button">
          <img src="/images/menu/contracts.webp" alt="Contracts" />
        </div>
        <div className="common-button">
          <img src="/images/menu/upgrades.webp" alt="Upgrades" />
        </div>
      </div>

      <div className="profile-section">
        {isLoggedIn && userData ? (
          <>
            {/* Profile View */}
            <button className="balance-button" onClick={handlePaymentClick}>
              Пополнить баланс
            </button>
            <div className="avatar">
              <img src={userData.avatar_url} alt={userData.name} />
              {/* Notification Badge for Unopened Cases */}
              {giftedCaseNotificationCount > 0 && (
                <div className="notification-badge">{giftedCaseNotificationCount}</div>
              )}
            </div>
            <div className="user-info">
              <div className="username">
                <a href="#!" onClick={handleProfileClick}>
                  {userData.name}
                </a>
              </div>
              <div className="balances">
                <div>{userData.balance} ₽</div>
              </div>
            </div>
            <div className="logout-image" onClick={handleLogout}>
              <img src="images/logout.png" alt="Logout" />
            </div>
          </>
        ) : (
          // Login Button
          <button className="common-button-login" onClick={handleLoginClick}>
            <span>ВОЙТИ</span>
          </button>
        )}
      </div>

      {/* Show Login Modal if showModal is true */}
      {showModal && <LoginModal closeModal={closeModal} />}
    </header>
  );
};

export default Header;
