import React, { useState } from 'react';
import axios from 'axios';
import CustomSelect from './CustomSelect'; // Adjust the path based on your structure
import { useAuth } from '../contexts/AuthContext'; // Assuming you have an AuthContext to provide user information

const Payment = () => {
    const { user, login } = useAuth(); // Retrieve the logged-in user's details and login function
    const [amount, setAmount] = useState(1000); // Default amount
    const [promoCode, setPromoCode] = useState(''); // Promo code state
    const [agreementChecked, setAgreementChecked] = useState(false); // Agreement checkbox state
    const [loading, setLoading] = useState(false); // Loading state
    const [message, setMessage] = useState(''); // Success/Error message

    const handleAmountChange = (e) => {
        setAmount(Number(e.target.value));
    };

    const handleDeposit = async () => {
        if (!user || !user.id) {
            setMessage('You must be logged in to deposit.');
            return;
        }
        if (!agreementChecked) {
            setMessage('You must accept the user agreement.');
            return;
        }
        setLoading(true);

        setMessage('');
        try {
            const response = await axios.post('/api/deposit', {
                user_id: user.id,
                amount,
            });

            if (response.data) {
                setMessage('Deposit successful!');

                // Fetch updated user data
                const userResponse = await axios.get(`/user/${user.id}`);
                if (userResponse.data) {
                    console.log("🚀 ~ handleDeposit ~ userResponse:", userResponse)
                    login(userResponse.data); // Update the user data in AuthContext
                }
            }
        } catch (error) {
            console.error('Error during deposit:', error);
            setMessage(
                error.response?.data?.error || 'An error occurred while depositing.'
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="payment-container">
            <h2>Хочу оплатить</h2>
            <div className="amount-selection">
                <input
                    type="number"
                    placeholder="1000 ₽"
                    value={amount}
                    onChange={handleAmountChange}
                />
                <CustomSelect />
            </div>
            <input
                className="promo-input"
                placeholder="Промокод"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
            />

            <div className="quick-select">
                {[350, 500, 1000, 1500, 3000].map((value) => (
                    <button key={value} onClick={() => setAmount(value)}>
                        {value}
                    </button>
                ))}
            </div>

            <div className="bonus-slider">
                <label>Бонус</label>
                <div className="slider">
                    <span>+5%</span>
                    <input type="range" min="0" max="5000" defaultValue="1000" />
                    <span>+15%</span>
                </div>
            </div>

            <h2>Получу</h2>
            <div className="total-amount">
                <span>{(amount * 1.05).toFixed(2)} ₽</span>
            </div>

            <div className="bonus-info">
                <div className="bonus-box">
                    <p>+5% к пополнению</p>
                </div>
                <div className="bonus-details">
                    <p>
                        Увеличить сумму пополнения до 2500 ₽ и получить бонус к пополнению
                        10%
                    </p>
                </div>
            </div>

            <div className="agreement">
                <input
                    type="checkbox"
                    id="agreement"
                    checked={agreementChecked}
                    onChange={() => setAgreementChecked(!agreementChecked)}
                />
                <label htmlFor="agreement">
                    Я принимаю <a href="#">пользовательское соглашение</a>
                </label>
            </div>

            <button
                className="pay-button"
                onClick={handleDeposit}
                disabled={loading || amount <= 0 || !agreementChecked}
            >
                {loading ? 'Processing...' : `Оплатить ${amount} ₽`}
            </button>

            {message && <p className="message">{message}</p>}
        </div>
    );
};

export default Payment;
